const messages = {
  list: {
    id: 'Id',
    server: 'Server',
    title: 'Titulek ankety',
    startOfVoting: 'Začátek ankety',
    endOfVoting: 'Konec ankety',
    loggedInUsersOnly: 'Jen přihlášení'
  },
  filter: {
    id: 'Id',
    title: 'Titulek ankety',
    server: 'Server'
  },
  server: 'Server',
  title: 'Titulek ankety',
  startOfVoting: 'Začátek ankety',
  endOfVoting: 'Konec ankety',
  displayType: 'Zobrazit',
  template: 'Šablona',
  restrictionTypes: 'Pravidla',
  image: 'Obrázek ankety',
  loggedInUsersOnlyLabel: 'Jen pro přihlášené uživatele',
  loggedInUsersOnlyLabelFalse: 'Ne',
  loggedInUsersOnlyLabelTrue: 'Ano',
  displayResultsBeforeVoting: 'Výsledky před hlasováním',
  addOption: 'Přidat možnost',
  hidden: 'Skrytý',
  enabledVoting: 'Povolit hlasování',
  minimumOptionsError: 'Anketa musí obsahovat alespoň dvě možnosti',
  yesNoTemplateMaxOptionsError: 'Anketa s šablonou ano/ne může obsahovat pouze dvě možnosti',
  isContest: 'Soutěžní anketa',
  contestId: 'ID soutěže (ze soutěžní aplikace)',
  contestSettings: 'Nastavení ankety',
  options: {
    answer: 'Odpověď',
    image: 'Obrázek odpovědi',
    voteCount: 'Počet hlasů'
  },
  displayTypesOptions: {
    votes: 'Hlasy',
    percentage: 'Procenta'
  },
  templateOptions: {
    default: 'Výchozí',
    yesNo: 'Ano ne',
    basic: 'Základní'
  },
  restrictionTypeOptions: {
    fiveMinutes: '1 x za 5 minut',
    fifteenMinutes: '1 x za 15 minut',
    thirtyMinutes: '1 x za 30 minut',
    sixtyMinutes: '1 x za 60 minut',
    oneDay: '1 x za Den',
    oneMonth: '1 x za Měsíc',
    onlyOnce: '1 x za celou dobu trvání'
  }
}

export default messages
