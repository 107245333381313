const messages = {
  id: 'Id',
  title: 'Nadpis',
  rubrics: 'Rubriky',
  search: 'Hledej',
  mainProduct: 'Hlavní produkt',
  loading_err: 'Chyba při načítání produktů'
}

export default messages
