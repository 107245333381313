const messages = {
  list: {
    id: 'Id',
    title: 'Název',
    identifier: 'Identifikátor',
    site: 'Web'
  },
  filter: {
    id: 'Id',
    title: 'Název',
    identifier: 'Identifikátor',
    site: 'Web',
    search: 'Hledat'
  },
  site: 'Web',
  title: 'Název',
  identifier: 'Identifikátor',
  poll: 'Anketa',
  identifier_validation: 'Musí být jedinečné'
}

export default messages
