const messages = {
  create_menu_label: 'Vytvořit menu',
  notify: {
    menu_created: 'Menu konfigurace byla úspěšně vytvořena',
    required_fields: 'Prosím, vyplňte všechna povinná pole'
  },
  form: {
    server: 'Server',
    menu_name: 'Název menu',
    state: 'Stav'
  },
  type_value: {
    active: 'Aktivní',
    inactive: 'Neaktivní'
  },
  switch: {
    shown: 'Zobrazeno'
  },
  list: {
    id: 'ID',
    server: 'Server',
    menu_name: 'Název menu'
  },
  itemmodal: {
    title_add: 'Přidat novou položku menu',
    title_edit: 'Upravit položku menu',
    pinboard_title_add: 'Přidat novou položku pinboardu',
    pinboard_title_edit: 'Upravit položku pinboardu',
    menu_structure_label: 'Umístění ve struktuře',
    menu_pinboard_label: 'Umístění v Pinboardu',
    menu_structure_menu: 'Menu (hlavní rubrika)',
    title_label: 'Název položky v menu',
    name_label: 'Nápověda (Title)',
    accesskey_label: 'Zkratka',
    type_label: 'Druh odkazu',
    rubric_label: 'Rubrika',
    tag_label: 'Redakční tag',
    geneea_tag_label: 'Geneea tag',
    special_label: 'Speciál',
    theme_label: 'Téma',
    series_label: 'Seriál',
    article_label: 'Článek',
    article_placeholder: 'Začněte psát název/titulek článku',
    url_label: 'URL',
    displayrestriction_label: 'Omezit na čas',
    displayfrom_label: 'Zobrazit od',
    displayto_label: 'Zobrazit do'
  },
  pinboard: {
    main_menu: 'Pinboard v hlavním menu',
    popup: 'Pinboard Popup',
    both: 'Oba Pinboardy'
  },
  menu: 'Menu',
  pinboard_title: 'Pinboard',
  add_item: 'Přidat položku',
  column: 'Sloupec',
  drag_label: 'Přetáhnout',
  modal_copy: {
    title: 'Vytvořit kopie menu',
    text: 'Chcete vytvořit kopii zvolené menu konfigurace?'
  },
  type: {
    rubric: 'Rubrika',
    tag: 'Tag - redakční',
    geneeaTag: 'Tag - Geneea',
    specialArticleGroup: 'Speciál',
    theme: 'Téma',
    series: 'Seriál',
    article: 'Článek',
    url: 'Odkaz',
    level_2: '2. úroveň',
    level_3: '3. úroveň'
  },
  filter: {
    server: 'Server',
    menu_name: 'Název menu',
    state: 'Stav',
    filter_label: 'Filtrovat'
  },
  linktype: {
    rubric: 'Rubrika',
    tag: 'Tag - redakční',
    geneeaTag: 'Tag - Geneea',
    specialArticleGroup: 'Speciál',
    theme: 'Téma',
    series: 'Seriál',
    article: 'Článek',
    url: 'Libovolná URL'
  }
}

export default messages
