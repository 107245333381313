const messages = {
  list: {
    id: 'Id',
    title: 'Titulek',
    slug: 'Slug',
    isVirtual: 'Virtuální rubrika',
    site: 'Stránka',
    parent: 'Rodič',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil',
    absolute_url: 'Absolutní URL',
    sorting: 'Seřazení subrubrik'
  },
  filter: {
    title: 'Titulek',
    id: 'Id',
    slug: 'Slug',
    site: 'Stránka',
    parent: 'Rodič',
    search: 'Hledat'
  },
  id: 'Id',
  title: 'Titulek',
  slug: 'Slug',
  textForSlug: 'Text pro Slug',
  site: 'Stránka',
  parent: 'Rodič',
  iptcCategory: 'IPTC kategorie',
  meta_title: 'Meta titulek',
  meta_description: 'Meta popis',
  meta_keywords: 'Meta klíčová slova',
  setting_advertCategory: 'ITM area parametr (advertCategory)',
  setting_chartbeatSection: 'Chartbeat sekce',
  setting_gemiusId: 'Gemius Id',
  setting_prRubric: 'PR rubrika',
  setting_includeInMenuSettings: 'Nabízet v Menu editoru',
  setting_special: 'Speciální rubrika',
  setting_advertSection: 'Unit sekce pro Google ads (advertSection)',
  sportTable_seasonId: 'Sportovní tabulka Season Id',
  sportTable_activeTab: 'Sportovní tabulka Aktivní záložka (první = 1)',
  rubric_without_articles: 'Rubrika bez článků (zakázat možnost nastavit tuto rubriku pro články, používá se pro vlastní seznamové rubriky)',
  hiddenInMenu: 'Skryté v automaticky generovaném menu',
  isArticleToppingEnabled: 'Topovat články',
  description: 'Popis rubriky',
  heroImage: 'Hero image (dostupný formát 16:5)',
  miniatureImage: 'Miniatura (dostupný formát 3:2)',
  isVirtualized: 'Virtuální rubrika',
  virtualized: 'Virtualizovat',
  unvirtualized: 'Zrušit virtualizaci',
  cannotVirtualize: 'Rubriku nebylo možné virtualizovat, protože je v kolizi s těmito rubrikami: ',
  movingForbidden: 'Přesun virtuální rubriky {rubricId} je zakázán.',
  sorting: 'Seřazení subrubrik',
  product: 'Produkt',
  enableVirtualizationMsg: 'Opravdu chcete virtualizovat rubriku?',
  disableVirtualizationMsg: 'Opravdu chcete zrušit virtualizaci rubriky?',
  conflictData: 'Rubrika koliduje s rubrikou:',
  rubricId: 'Id rubriky',
  rubricTitle: 'Název rubriky',
  groupRubricIds: 'Skupina stejných rubrik',
  typeToSearch: 'Zadejte minimálně {min} znaky pro vyhledání',
  empty_result: 'Žádné výsledky',
  shareToRubric: 'Sdílet do rubriky',
  defaultPosition: 'Pozicovat v rubrice',
  dateSince: 'Pozicovat v rubrice od',
  dateUntil: 'Pozicovat v rubrice do',
  showUntil: 'Aktuálnost článku',
  please_select_the_daily: 'Prosím vyberte denník',
  positionInRubric: 'Pozice v rubrice'
}

export default messages
