const messages = {
  title: 'Nastavení vah',
  add: 'Přidat zobrazení',
  save: 'Uložit',
  group: 'Skupina',
  name: 'Název',
  printQuality: 'Kvalita Printu',
  online: 'Kvalita Online',
  subscriptions: 'Předplatné',
  socialEngagement: 'Zapojení na sociálních sítích',
  videoQuality: 'Kvalita Videa',
  articlesQuality: 'Kvalita Článků',
  videoQuantity: 'Kvantita Videa',
  tableListing: {
    printQuality: {
      printQualityA: 'Kvalita Printu A',
      printQualityB: 'Kvalita Printu B',
      printQualityC: 'Kvalita Printu C'
    },
    online: {
      pageViews: 'Zobrazení Stránek',
      engagedPageView: 'Zapojená Zobrazení Stránek'
    },
    subscriptions: {
      subscriptions: 'Předplatné'
    },
    socialEngagement: {
      disqus: 'Disqus',
      fbShares: 'Sdílení na Facebooku'
    },
    videoQuality: {
      videoViews: 'Zobrazení Videa',
      videoPlaythrough: 'Přehrání Videa'
    },
    articlesQuality: {
      printQuantityP1Author: 'Kvantita Printu P1 Autor',
      printQuantityP1Agency: 'Kvantita Printu P1 Agentura',
      printQuantityP2Author: 'Kvantita Printu P2 Autor',
      printQuantityP2Agency: 'Kvantita Printu P2 Agentura',
      onlineAuthor: 'Online Autor',
      fromPrint: 'Z Printu',
      agency: 'Agenturní',
      republished: 'Přepublikovaný',
      quiz: 'Kvíz',
      photostory: 'Fotostory'
    },
    videoQuantity: {
      videoInternal: 'Video - interní',
      videoSlideshow: 'Video - slideshow',
      videoSocial: 'Video - sociální',
      videoAgency: 'Video - agenturní'
    }
  }
}

export default messages
