const messages = {
  list: {
    id: 'Id',
    site: 'Web',
    source_url: 'Zdrojová URL',
    destination_url: 'Cílová URL',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil'
  },
  filter: {
    site: 'Web',
    source_url: 'Zdrojová URL',
    destination_url: 'Cílová URL',
    id: 'Id',
    search: 'Hledat'
  },
  id: 'Id',
  site: 'Web',
  source_url: 'Zdrojová URL',
  destination_url: 'Cílová URL'
}

export default messages
