const messages = {
  list: {
    id: 'Id',
    title: 'Název',
    name: 'Jméno',
    site: 'Web'
  },
  filter: {
    id: 'Id',
    title: 'Název',
    name: 'Jméno',
    site: 'Web',
    search: 'Hledat'
  },
  site: 'Web',
  title: 'Název',
  name: 'Jméno',
  name_validation: 'Musí obsahovat pouze \'a-z\' a \'-\' a musí být jedinečné'
}

export default messages
