const messages = {
  list: {
    userId: 'Id',
    username: 'Uživatelské jméno',
    responseStatus: 'Stav odpovědi',
    created_at: 'Datum vytvoření',
    clientIp: 'IP',
    adminVersion: 'Verze administrace'
  },
  filter: {
    userId: 'Id',
    username: 'Uživatelské jméno',
    responseStatus: 'Stav odpovědi',
    created_at_from: 'Datum vytvoření od',
    created_at_to: 'Datum vytvoření do',
    clientIp: 'IP',
    search: 'Hledat'
  }
}

export default messages
