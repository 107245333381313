const messages = {
  id: 'ID',
  title: 'Titulek',
  native: 'Nativní',
  quality: 'Kvalita',
  source: 'Zdroj',
  type: 'Typ',
  sharedUsers: 'Autorský podíl',
  publication: 'publikace',
  save: 'Ulož',
  saveAndAddNew: 'Ulož a ​​přidej nový',
  rubric: 'Rubrika',
  printTitleId: 'Titul',
  yes: 'Ano',
  no: 'Ne',
  filter: {
    search: 'Hledat',
    reset_filter: 'Zrušit filtr'
  },
  error: {
    required_fields: 'Prosím, vyplňte všechna povinná pole: '
  },
  message: {
    updated_record: 'Záznam byl změněn!',
    deleted_record: 'Záznam byl vymazán!'
  },
  orderDate: 'Datum publikování'
}

export default messages
