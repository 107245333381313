const messages = {
  list: {
    id: 'Id',
    article_doc_id: 'ID dokumentu článku',
    article_id: 'ID článku',
    changed_at: 'Změněno',
    change_type: 'Typ změny',
    changed_by: 'Změnil'
  },
  filter: {
    id: 'Id',
    article_doc_id: 'ID dokumentu článku',
    article_id: 'ID článku',
    changed_at_from: 'Změněno od',
    changed_at_to: 'Změněno do',
    change_type: 'Typ změny',
    changed_by: 'Změnil',
    search: 'Hledat'
  }
}

export default messages
