const messages = {
  filter: {
    server: 'Server',
    type: 'Typ',
    originalUrl: 'Originální URL',
    alias: 'Alias',
    validFrom: 'Platnost od',
    validTo: 'Platnost do'
  },
  id: 'Id',
  server: 'Server',
  originalUrl: 'Originální URL',
  alias: 'Alias',
  aliasUrl: 'URL Alias',
  validFrom: 'Platnost od',
  reservedUntil: 'Platnost do',
  selectDate: 'Vyberte dátum',
  status: 'Stav',
  description: 'Poznámka',
  redirectType: 'Typ přesměrování',
  redirectTypes: {
    permanent: '301: Permanentní',
    temporary: '302: Dočasné'
  },
  type: 'Typ',
  aliasType: 'Alias / Přesměrování URL',
  aliasTypes: {
    alias: 'Alias',
    redirect: 'Přesměrování'
  },
  statuses: {
    new: 'Nový',
    active: 'Aktivní',
    inactive: 'Neaktivní',
    deleted: 'Smazané'
  },
  audits: {
    date: 'Datum',
    user: 'Uživatel',
    description: 'Popis akce',
    create: 'Vytvořen alias',
    update: 'Upraven alias',
    delete: 'Odstraněn alias'
  },
  errors: {
    nonExistingOriginalUrl: 'Musíte zadat platnou URL adresu (např. URL adresu existujícího článku)',
    invalidOriginalUrl: 'Musíte zadat platnou URL adresu'
  }
}

export default messages
