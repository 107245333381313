const messages = {
  createModule: 'Vytvořit modul',
  viewModule: 'Náhled modulu',
  editModule: 'Editovat modul',
  moduleId: 'ID modulu',
  createdBy: 'Vytvořil (datum vytvoření)',
  modifiedBy: 'Poslední změna (datum změny)',
  infobox: 'Infobox',
  joke: 'Vtip',
  quote: 'Myšlenka týdne',
  poll: 'Anketa',
  likeDislike: 'Líbí / Nelíbí',
  quiz: 'Kvíz',
  quizOneQuestion: 'Jedna otázka',
  sport24: 'Sportovní tabulka',
  topic: 'Téma',
  recipe: 'Recept',
  video: 'Video',
  gallery: 'Galerie',
  redirect: 'Přesměrování',
  hottopics: 'Hot Topics',
  breakingnews: 'Breaking News',
  rightNow: 'Právě teď',
  newsOfTheDay: 'Zprávy dne',
  embeddedApplication: 'VLM aplikace',
  menuEditor: 'Menu editor'
}

export default messages
