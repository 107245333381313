const messages = {
  list: {
    id: 'Id',
    site: 'Stránka',
    rubric: 'Rubrika',
    service: 'Služba',
    code: 'Kód',
    title: 'Titulek'
  },
  filter: {
    site: 'Stránka',
    rubric: 'Rubrika',
    service: 'Služba',
    code: 'Kód',
    title: 'Titulek',
    search: 'Hledat'
  },
  id: 'Id',
  site: 'Stránka',
  rubric: 'Rubrika',
  service: 'Služba',
  code: 'Kód',
  title: 'Titulek',
  error: {
    codeLimit: 'Kód musí mít přesně {limit} znaky'
  }
}

export default messages
