const messages = {
  list: {
    id: 'Id',
    name: 'Název',
    description: 'Popis',
    enabled: 'Povoleno',
    data: 'Data',
    created_at: 'Datum vytvoření',
    modified_at: 'Datum poslední změny',
    created_by: 'Vytvořil'
  },
  filter: {
    id: 'Id',
    name: 'Název',
    search: 'Hledat'
  },
  id: 'Id',
  name: 'Název',
  description: 'Popis',
  enabled: 'Povoleno',
  data: 'Data'
}

export default messages
