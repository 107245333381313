const messages = {
  source: 'Zdroj',
  firstName: 'Jméno',
  lastName: 'Příjmení',
  customText: 'Vlastní text',
  user: 'Uživatel',
  url: 'URL',
  shortcut: 'Zkratka',
  sex: 'Pohlaví',
  sexMale: 'muž',
  sexFemale: 'žena',
  function: 'Funkce',
  typeAuthor: 'Autor',
  typeEditor: 'Editor',
  typeSportEditor: 'Sportovní redaktor',
  typeReaderReporter: 'Čtenář reportér',
  typePhotographer: 'Fotograf/Video',
  typeExternalAuthor: 'Externí autor',
  phone: 'Telefon',
  email: 'E-mail',
  showContactForm: 'Zobrazit kontaktní formulář',
  defaultSite: 'Domovský server',
  sites: 'Servery',
  editorSites: 'Redaktor serverů',
  editorInChiefSites: 'Šéfredaktor serverů',
  otherFunction: 'Další funkce',
  hiddenOnPage: 'Skrýt autora na webu',
  hiddenOnPage_short: 'Skrýt na webu',
  disabled: 'Neaktivní',
  authorImage: 'Fotografie autora',
  showCompleteProfile: 'Profil autora úplný',
  briefProfile: 'Stručný profil autora',
  completeProfile: 'Úplný profil autora',
  twitter: 'URL Twitter profil',
  linkedin: 'URL LinkedIn profil',
  facebook: 'URL Facebook profil',
  instagram: 'URL Instagram profil',
  pinterest: 'URL Pinterest profil',
  warningMessages: {
    duplicateEntryInServers: 'Pole Servery obsahuje duplicitní záznam s domovským serverem. Po uložení bude duplikát odstraněn.'
  }
}

export default messages
